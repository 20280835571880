.persons {
  width: 100%;
  height: 385rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));
}

.persons .persons-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));  
}

.persons .persons-content .group {
  display: grid;
  grid-template-columns: repeat(1fr);
}

.persons .persons-content p {
  font: 500 1.5rem barlow;
  color: var(--color-text-white);
}

.persons .persons-content a {  
  text-decoration-color: var(--color-text-white);
}

@media (min-width: 600px) {
  .persons {    
    height: 450rem;   
  }

  .persons .persons-content p {
    font: 500 1.8rem barlow;    
  }
}

@media (min-width: 1200px) {
  .persons {    
    height: 310rem;   
  }

  .persons .persons-content .group {   
    grid-template-columns: 1fr 1fr;    
  }

  .persons .persons-content p {
    font: 500 2.2rem barlow;    
  }
}
