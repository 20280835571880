.person {
  width: 29.1rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;  
}

.person .images {
  width: 100%;
  height: 16rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: var(--color-background-light);
}

.person #name {
  font: 500 2rem barlow;
  color: var(--color-text-white);
}

.person .images .foto { /*0.87*/
  width: 13.05rem;
  height: 15rem; 
}

.person .images .foto img {
  width: 100%;
  height: 100%;
}

.person .images .mapa { /*0.87*/
  width: 13.05rem;
  height: 15rem;
}

.person .images .mapa img {
  width: 100%;
  height: 100%;
}

@media (min-width: 600px) {
  .person {
    width: 32.58rem;
    height: 23.3rem;
    margin-bottom: 3rem;    
  }
  
  .person .images {
    width: 100%;
    height: 18.7rem;   
  }
  
  .person #name {
    font: 500 2.5rem barlow;    
  }
  
  .person .images .foto {
    width: 14.79rem;
    height: 17rem;   
  }
  
  .person .images .foto img {
    width: 100%;
    height: 100%;
  }
  
  .person .images .mapa {
    width: 14.78rem;
    height: 17rem;
  }
  
  .person .images .mapa img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .person {
    width: 46.5rem;
    height: 32rem;   
    margin-left: 3rem;
    margin-right: 3rem;   
  }
  
  .person .images {
    width: 100%;
    height: 26.5rem;   
  }
  
  .person #name {
    font: 500 3rem barlow;    
  }
  
  .person .images .foto {
    width: 21.75rem;
    height: 25rem;   
  }
  
  .person .images .foto img {
    width: 100%;
    height: 100%;
  }
  
  .person .images .mapa {
    width: 21.75rem;
    height: 25rem;
  }
  
  .person .images .mapa img {
    width: 100%;
    height: 100%;
  }
}