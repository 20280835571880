.globe {
  width: 100%;
  height: 50rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));
}

.globe .globe-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));
}

.globe .globe-content .globe-image {
  width: 90%;
  height: width;  
}

.globe .globe-content .globe-image img {
  width: 100%;
  height: 100%;
}

@media (min-width: 600px) {
  .globe {
    height: 55rem;
  }
  
  .globe .globe-content .globe-image {
    width: 55rem;
    height: 55rem;
  }
}

@media (min-width: 1200px) {
  .globe {
    height: 70rem;
  }
  
  .globe .globe-content .globe-image {
    width: 65rem;
    height: 65rem;
  }
}