@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    font-size: 60%;
    --color-background: #51517B;
    --color-background-dark: #000000;
    --color-background-light: #EFEFEF;
    --color-text-white: #FFFFFF; 
    --color-text-blue: #001857;
    --color-text-box: #FFFFFF;
    --color-text-shadow: #a0a0a0;         
}

* {
    margin: 0;
    padding: 0;    
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: var(--color-background-dark);   
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
input,
button,
textarea {
    font: 500 1.6rem barlow;
    color: var(--color-text-white);
}

.container {
    width: 90vw;    
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}
