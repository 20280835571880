.person-map {
  width: 100%;
  height: 100rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));
}

.person-map .person-map-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));  
}

.person-map .person-map-content .map-block {
  width: 100%;
  height: 95%;
  background-image: url('../../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.person-map .person-map-content .map-block h2 {
  font: 700 3.2rem barlow;
  color: var(--color-text-blue);
  margin-bottom: 1rem;
}

.person-map .person-map-content .map-block strong {
  font: 700 2.5rem barlow;
  color: var(--color-text-blue);
  margin-bottom: 1rem;
}

.person-map .person-map-content .map-block .numerology-images {
  width: 28rem;
  height: 62.76rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: var(--color-background-light);
  margin-bottom: 2rem;
}

.person-map .person-map-content .map-block .numerology-foto { /*0.87*/
  width: 26rem;
  height: 29.88rem; 
}

.person-map .person-map-content .map-block .numerology-foto img {
  width: 100%;
  height: 100%;
}

.person-map .person-map-content .map-block .numerology-map { /*0.87*/
  width: 26rem;
  height: 29.88rem;
}

.person-map .person-map-content .map-block .numerology-map img {
  width: 100%;
  height: 100%;
}

.person-map .person-map-content .map-block .player-wrapper {
  margin-bottom: 2rem;
}

.person-map .person-map-content .map-block p {
  font: 600 1.5rem barlow;
  color: var(--color-text-blue);
}

@media (min-width: 600px) {
  .person-map {    
    height: 110rem;   
  }

  .person-map .person-map-content .map-block h2 {
    font: 700 3.8rem barlow;    
  }
  
  .person-map .person-map-content .map-block strong {
    font: 700 2.8rem barlow;
    color: var(--color-text-blue);
    margin-bottom: 1.2rem;
  }
  
  .person-map .person-map-content .map-block .numerology-images {
    width: 32rem;
    height: 71.96rem;    
  }
  
  .person-map .person-map-content .map-block .numerology-foto { /*0.87*/
    width: 30rem;
    height: 34.48rem; 
  } 
  
  .person-map .person-map-content .map-block .numerology-map { /*0.87*/
    width: 30rem;
    height: 34.48rem;
  } 

  .person-map .person-map-content .map-block p {
    font: 600 1.8rem barlow;   
  }
}

@media (min-width: 1200px) {
  .person-map {    
    height: 110rem;   
  }

  .person-map .person-map-content .map-block h2 {
    font: 700 5rem barlow;
    margin-bottom: 2rem;   
  }
  
  .person-map .person-map-content .map-block strong {
    font: 700 3.5rem barlow;
    color: var(--color-text-blue);
    margin-bottom: 1.2rem;
  }
  
  .person-map .person-map-content .map-block .numerology-images {
    width: 100rem;
    height: 57.74rem;
    flex-direction: row;
    margin-bottom: 4rem; 
  }
  
  .person-map .person-map-content .map-block .numerology-foto { /*0.87*/
    width: 48.5rem;
    height: 55.74rem; 
  } 
  
  .person-map .person-map-content .map-block .numerology-map { /*0.87*/
    width: 48.5rem;
    height: 55.74rem;
  }

  .person-map .person-map-content .map-block .player-wrapper {
    margin-bottom: 4rem;
  }

  .person-map .person-map-content .map-block p {
    font: 600 2.2rem barlow;   
  }
}
