.header {
  width: 100%;
  height: 35rem;  
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));  
}

.header .header-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(to right, var(--color-background-dark), var(--color-background));  
}

.header .header-content .logo {
  width: 15rem;
  height: 15rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.header .header-content .logo img {
  width: 100%;
  height: 100%;
}

.header .header-content .text-block {
  width: 30rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.header .header-content .text-block h1 {
  font: 600 2.2rem barlow;
  color: var(--color-text-white);
  text-shadow: 1px 1px var(--color-text-shadow);
  text-align: center;
  margin-bottom: 3rem;
}

.header .header-content .text-block h2 {
  font: 600 1.8rem barlow;
  color: var(--color-text-white);
  text-align: center;
}

@media (min-width: 600px) {
  .header {   
    height: 38rem;   
  }

  .header .header-content .logo {
    width: 18rem;
    height: 18rem;
    margin-bottom: 1rem;   
  }  
  
  .header .header-content .text-block {
    width: 55rem;   
  }
  
  .header .header-content .text-block h1 {
    font: 600 2.4rem barlow;   
  }  
}

@media (min-width: 1200px) {
  .header .header-content .logo {
    width: 20rem;
    height: 20rem;
    margin-bottom: 0;  
  }  
  
  .header .header-content .text-block {
    width: 90rem;   
  }
  
  .header .header-content .text-block h1 {
    font: 700 4rem barlow;
    text-shadow: 2px 2px var(--color-text-shadow);  
  }
  
  .header .header-content .text-block h2 {
    font: 600 2rem barlow;    
  }
}

@media (min-width: 1400px) {
  .header .header-content .text-block {
    width: 120rem;   
  }
  
  .header .header-content .text-block h1 {
    font: 700 4.5rem barlow;
    text-shadow: 2px 2px var(--color-text-shadow);  
  }
  
  .header .header-content .text-block h2 {
    font: 600 2.5rem barlow;    
  }
}